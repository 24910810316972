const styles = {
  container: {
    position: 'relative',
    height: '100%',
  },
  reactPlayer: {
    zIndex: -1,
  },
};

export default styles;
